import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import ZeroState from 'shared/ZeroState';

import { FontSizes, FontWeights } from 'constants/Clementine';

class ProductResources extends React.Component {
  static propTypes = {
    quote: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      hover_form_index: null
    };
  }

  _handleFormHover = (form_guid = null) => {
    this.setState({
      hover_form_index: form_guid
    });
  };

  _handleFormClick = form_url => {
    window.open = form_url;
  };

  render() {
    const styles = this.styles();
    const { quote } = this.props;
    const { hover_form_index } = this.state;

    if (quote.product.resources.length) {
      return (
        <div style={styles.grid_wrapper}>
          {quote.product.resources.map((r, i) => {
            return (
              <a href={r.url} key={i} onMouseEnter={this._handleFormHover.bind(null, i)} onMouseLeave={this._handleFormHover} style={styles.form} target='_blank'>
                <div style={styles.form_name}>{r.label}</div>
                <div style={styles.preview_content}>{hover_form_index === i ? <i className='mdi mdi-open-in-new' style={styles.hover_icon} /> : <i className='mdi mdi-file-document-outline' />}</div>
              </a>
            );
          })}
        </div>
      );
    } else {
      return <ZeroState icon='mdi-file-search' message='No forms found.' />;
    }
  }

  styles = () => {
    return {
      grid_wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 40
      },
      form: {
        width: '24%',
        height: 250,
        margin: '0 1% 2%',
        position: 'relative',
        border: '1px solid #e5e5e5',
        borderRadius: 4,
        overflow: 'hidden',
        cursor: 'pointer',
        display: 'block',
        textDecoration: 'none'
      },
      preview_content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 60,
        color: '#f5f5f5',
        marginTop: 50
      },
      form_name: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e5e5e5',
        padding: 10,
        fontSize: FontSizes.REGULAR,
        fontWeight: FontWeights.BOLD,
        height: 50,
        color: this.context.color
      },
      hover_icon: {
        color: this.context.color
      }
    };
  };
}

ProductResources.contextType = ThemeContext;

export default ProductResources;
