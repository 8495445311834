import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Modal from 'shared/Modal';
import FireLightEmbed from 'shared/FireLightEmbed';

import { Colors, FontFamilies, FontSizes, FontWeights } from 'constants/Clementine';

//import AgentLoginForm from './AgentLoginForm';
import LtcApi from '../../../utils/LtcApi';
import { LtcContext } from '../Context';

class FirelightEappModal extends React.Component {
  static propTypes = {
    action: PropTypes.object.isRequired,
    eapp: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      current_view: 'loading'
    };
  }

  componentDidMount() {
    const { eapp } = this.props;
    //const { agent } = this.context;

    //if (agent && agent.id) {
    //  if (eapp) {
    //    this.setState({ current_view: 'application' });
    //  } else {
    //    this._createApplication();
    //  }
    //} else {
    //  this.setState({ current_view: 'login' });
    //}
    if (eapp) {
      this.setState({ current_view: 'application' });
    } else {
      this._createApplication();
    }
  }

  _createApplication = () => {
    const { consumer_first_name, consumer_last_name } = this.context.criteria;
    const { member_id } = this.context.quoter;
    const { ltc_quoter_id } = this.props.action;
    const { id } = this.props.quote[0];
    //const { quote } = this.props;

    this.setState({
      current_view: 'loading'
    });
    const params = {
      member_id,
      payment_mode: 'monthly',
      provider: 'firelight',
      quote_guid: id,
      app_data: {
        // this should contain any data we want to pre-fill
        pi_first_name: consumer_first_name || '',
        pi_last_name: consumer_last_name || '',
        payment_mode: 'monthly'
      }
    };

    LtcApi.createEApp({ ltc_quoter_id, params })
      .then(({ data }) => {
        this.props.onSave(data);
        this.setState({ current_view: 'application' });
      })
      .catch(() => {
        this.setState({ current_view: 'error' });
      });
  };

  _handleFireLightStatusChange = status => {
    const { ltc_quoter_id } = this.props.action;
    LtcApi.updateEApp({ id: this.props.eapp.id, ltc_quoter_id, params: { provider_status: status } }).then(({ data }) => {
      this.props.onSave(data);
    });
  };

  _handleFireLightError = error_msg => {
    this.setState({ current_view: 'loading' });
    const { ltc_quoter_id } = this.props.action;

    LtcApi.updateEApp({ id: this.props.eapp.id, ltc_quoter_id, params: { provider_status: 'error', provider_response: error_msg } }).then(({ data }) => {
      this.props.onSave(data);
      this.setState({ current_view: 'application' });
    });
  };

  render() {
    const { action, onClose, eapp } = this.props;
    const { current_view } = this.state;
    const styles = this.styles();

    return (
      <Modal fullScreen={true} loading={current_view === 'loading'} maxWidth='none' onClose={onClose} title={action.label}>
        {/*{current_view === 'login' ? (*/}
        {/*  <div style={{ width: 450, margin: '0 auto' }}>*/}
        {/*    <AgentLoginForm loginDescription='You must login with your IXN account to proceed. Once logged in you will be able to continue to the application' onLogin={this._createApplication} />*/}
        {/*  </div>*/}
        {/*) : null}*/}

        {current_view === 'application' ? (
          <FireLightEmbed
            activityId={eapp.provider_tracking_id}
            activityType='e_app'
            environment={eapp.app_data.environment}
            errorMessage={eapp.app_data.error || eapp.provider_response}
            onError={this._handleFireLightError}
            onStatusChange={this._handleFireLightStatusChange}
            status={eapp.provider_status}
            successMessage='Your application has been completed. If you elected to e-sign, then the application will be auto-submitted to the carrier. If you declined e-sign or e-sign was not available, then you will need to download the PDF using the button below to collect wet signatures.'
            token={eapp.app_data.token}
          />
        ) : null}

        {current_view === 'error' ? (
          <div style={styles.content}>
            <div style={styles.icon_wrapper}>
              <i className='mdi mdi-alert-outline' style={styles.icon} />
            </div>
            <div style={styles.title}>An error occurred while trying to display FireLight</div>
            <div style={styles.copy}>We are unable to start an e-application at this time. Please contact support for further assistance.</div>
            <Button onClick={onClose}>Back To Quote Results</Button>
          </div>
        ) : null}
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        maxWidth: 550,
        height: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      icon_wrapper: {
        width: 120,
        height: 120,
        borderRadius: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 40,
        background: `rgba(${Colors.RED.rgb}, 0.2)`
      },
      icon: {
        fontSize: 42,
        color: Colors.RED.hex
      },
      title: {
        fontSize: FontSizes.XLARGE,
        fontFamily: FontFamilies.ACCENT,
        fontWeight: FontWeights.BOLD,
        marginBottom: 10
      },
      copy: {
        fontSize: FontSizes.MEDIUM,
        lineHeight: '24px',
        marginBottom: 20,
        textAlign: 'center'
      }
    };
  };
}

FirelightEappModal.contextType = LtcContext;

export default FirelightEappModal;
