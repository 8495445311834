import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';

import { ThemeContext } from 'shared/ThemeContext';

import { FontFamilies, FontSizes, FontWeights, Colors } from 'constants/Clementine';

import { SA_BASE_URL } from 'config/App';

class Intro extends React.Component {
  static propTypes = {
    onDontShowClick: PropTypes.func,
    onViewCriteriaClick: PropTypes.func
  };

  render() {
    const { onDontShowClick, onViewCriteriaClick } = this.props;
    const styles = this.styles();

    return (
      <div style={styles.contents}>
        <div style={styles.pageTitle}>Welcome!</div>
        <div style={styles.pageDescription}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ultrices diam at enim viverra euismod. Maecenas ac purus a sapien vestibulum consequat vel ut ex. Curabitur molestie semper
          laoreet.
        </div>
        <img src={`${SA_BASE_URL}/ixn/images/fake_video.png`} style={styles.video} />

        <div style={styles.actions}>
          <Button icon='tune-vertical' inline={true} onClick={onViewCriteriaClick}>
            View Case Design
          </Button>
          <div onClick={onDontShowClick} style={styles.link}>
            Don't Show This Again
          </div>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      contents: {
        textAlign: 'center',
        padding: 40
      },
      pageTitle: {
        fontSize: 30,
        fontFamily: FontFamilies.ACCENT,
        fontWeight: FontWeights.MEDIUM
      },
      pageDescription: {
        fontFamily: FontFamilies.MAIN,
        fontSize: FontSizes.REGULAR,
        fontWeight: FontWeights.REGULAR,
        color: Colors.GRAY.hex,
        lineHeight: '20px',
        width: 350,
        margin: '20px auto 0'
      },
      video: {
        borderRadius: 6,
        width: 520,
        height: 'auto',
        margin: '30px auto 0'
      },
      actions: {
        marginTop: 35
      },
      link: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM,
        color: this.context.color,
        marginTop: 20,
        cursor: 'pointer'
      }
    };
  };
}

Intro.contextType = ThemeContext;

export default Intro;
