import PropTypes from 'prop-types';
import React from 'react';

import Button from 'shared/Button';
import Input from 'shared/Input';
import Modal from 'shared/Modal';

import { FontSizes } from 'constants/Clementine';

import LtcApi from 'utils/LtcApi';

import { BASE_LTC_URL, SA_BASE_URL } from 'config/App';
import { LtcContext } from '../Context';

const BASE_CONSUMER_URL = `${BASE_LTC_URL}/consumer.html?id=${window.IXN_LTC_CONFIG.id}`;
const BASE_PRODUCER_URL = `${BASE_LTC_URL}/producer.html?id=${window.IXN_LTC_CONFIG.id}`;

class ShareModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    quotes: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      link_id: null,
      loading: true
    };
  }

  componentDidMount = () => {
    LtcApi.createQuoteLink(this.props.quotes).then(link_id => {
      this.setState({
        link_id,
        loading: false
      });
    });
  };

  _getLink = type => {
    const base_url = type === 'consumer' ? BASE_CONSUMER_URL : BASE_PRODUCER_URL;

    return `${base_url}&qlid=${this.state.link_id}`;
  };

  _handleOpenLink = type => {
    const link = this._getLink(type);

    window.open(link);
  };

  render() {
    const { link_id, loading } = this.state;
    const { onClose } = this.props;
    const styles = this.styles();

    return (
      <Modal loading={loading} maxWidth={450} onClose={onClose} title='Share Selected Quotes'>
        <div style={styles.container}>
          <img src={`${SA_BASE_URL}/ixn/images/share_producer.png`} style={styles.image} />
          <div style={styles.description}>
            This link is designed specifically for those that are assisting in the sale of long term care insurance. The viewer will have access to all the features and functionality that are present
            in this tool.
          </div>
          <div style={styles.buttons}>
            {link_id ? (
              <div style={styles.link}>
                <Input readOnly={true} style={styles.link_input} value={this._getLink('producer')} />
                <Button inline={true} onClick={this._handleOpenLink.bind(null, 'producer')} style={{ margin: '10px 0 25px' }}>
                  Open Link
                </Button>
              </div>
            ) : (
              <Button icon='content-copy' inline={true} onClick={this._generateLink}>
                Generate Link
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      container: {
        padding: '25px 0px',
        position: 'relative'
      },
      image: {
        maxWidth: '100%'
      },
      description: {
        fontSize: FontSizes.MEDIUM,
        lineHeight: '24px',
        padding: '20px 40px 30px',
        textAlign: 'center'
      },
      buttons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
      },
      link: {
        width: '100%',
        padding: '0 40px'
      },
      link_input: {
        display: 'block',
        marginBottom: 10
      }
    };
  };
}

ShareModal.contextType = LtcContext;

export default ShareModal;
