import React from 'react';
import PropTypes from 'prop-types';

import Field from 'shared/Field';
import FieldGroup from 'shared/FieldGroup';
import Input from 'shared/Input';
import Button from 'shared/Button';
import Select from 'shared/Select';
import Checkbox from 'shared/Checkbox';
import PowerSelect from 'shared/PowerSelect';
import DatePicker from 'shared/DatePicker';

import { States } from 'constants/Clementine';
import { LtcContext } from '../Context';

//import { LtcContext } from 'ltc/Context';

class Criteria extends React.Component {
  static propTypes = {
    onCheckboxChange: PropTypes.func,
    onDobChange: PropTypes.func,
    onFieldChange: PropTypes.func,
    onGetQuotes: PropTypes.func,
    onPowerSelectChange: PropTypes.func,
    onResetCriteria: PropTypes.func,
    onToggleAge: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { onCheckboxChange, onPowerSelectChange, onFieldChange, onDobChange, onGetQuotes, onResetCriteria, onToggleAge } = this.props;
    const { required_fields, fields_options, criteria, errors, show_age } = this.context;
    const styles = this.styles();
    const is_ca_ny = ['CA', 'NY'].includes(criteria.state);

    const benefitAmountTitle = {
      'Monthly Benefit': 'Desired Monthly Benefit',
      'Total Benefit': 'Desired Total Benefit',
      'Daily Amount': 'Desired Daily Amount',
      Premium: 'Desired Premium',
      'Specified Amount': 'Desired Amount'
    };
    let paymentSchedules = ['Single Premium', '5-Pay', '10-Pay', 'Pay to Age 65', 'Pay to Age 100'];
    let productInflations = ['3% Simple', '3% Compound', '5% Compound', 'None'];
    const paymentFrequency = criteria.scheduled_premium === 'Single Premium' ? ['Annual'] : ['Monthly', 'Annual'];

    if (criteria.refund_of_premiums.includes('Maximum LTC Benefit')) {
      productInflations.push('US Medical Care Inflation');
    }

    if (is_ca_ny) {
      paymentSchedules = ['Single Premium', '5-Pay', '10-Pay'];
      productInflations = ['3% Simple', '5% Compound', 'None'];
    }

    return (
      <div style={styles.content}>
        <div style={styles.criteria}>
          <div style={{ width: '35%' }}>
            <FieldGroup label='Producer Information' style={{ marginBottom: 30 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field label='First Name' style={{ width: '39%' }}>
                  <Input
                    invalid={errors.includes('producer_first_name')}
                    onChange={onFieldChange.bind(null, 'producer_first_name', true)}
                    required={required_fields.includes('producer_first_name')}
                    value={criteria.producer_first_name}
                  />
                </Field>
                <Field label='Last Name' style={{ width: '59%' }}>
                  <Input
                    invalid={errors.includes('producer_last_name')}
                    onChange={onFieldChange.bind(null, 'producer_last_name', true)}
                    required={required_fields.includes('producer_last_name')}
                    value={criteria.producer_last_name}
                  />
                </Field>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field label='Email' style={{ width: '49%' }}>
                  <Input
                    invalid={errors.includes('producer_email')}
                    onChange={onFieldChange.bind(null, 'producer_email', true)}
                    required={required_fields.includes('producer_email')}
                    value={criteria.producer_email}
                  />
                </Field>
                <Field label='Phone' style={{ width: '49%' }}>
                  <Input
                    format='phone'
                    invalid={errors.includes('producer_phone')}
                    onChange={onFieldChange.bind(null, 'producer_phone', true)}
                    required={required_fields.includes('producer_phone')}
                    value={criteria.producer_phone}
                  />
                </Field>
              </div>
            </FieldGroup>

            <FieldGroup label='Insured Information'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field label='First Name' style={{ width: '39%' }}>
                  <Input
                    invalid={errors.includes('consumer_first_name')}
                    onChange={onFieldChange.bind(null, 'consumer_first_name', true)}
                    required={required_fields.includes('consumer_first_name')}
                    value={criteria.consumer_first_name}
                  />
                </Field>
                <Field label='Last Name' style={{ width: '59%' }}>
                  <Input
                    invalid={errors.includes('consumer_last_name')}
                    onChange={onFieldChange.bind(null, 'consumer_last_name', true)}
                    required={required_fields.includes('consumer_last_name')}
                    value={criteria.consumer_last_name}
                  />
                </Field>
              </div>
              {show_age ? (
                <Field action={{ label: 'Enter DOB', onClick: onToggleAge.bind(null, false) }} label='Age'>
                  <Input invalid={errors.includes('age')} onChange={onFieldChange.bind(null, 'age', true)} required={true} value={criteria.age} />
                </Field>
              ) : (
                <Field action={{ label: 'Enter Age', onClick: onToggleAge.bind(null, true) }} label='Date of Birth'>
                  <DatePicker hidePlaceholders={true} invalid={errors.includes('date_of_birth')} onChange={onDobChange} required={true} value={criteria.date_of_birth} />
                </Field>
              )}

              <div style={styles.row}>
                <Field label='Gender' style={styles.field}>
                  <Select
                    onChange={onFieldChange.bind(null, 'gender', true)}
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' }
                    ]}
                    required={true}
                    value={criteria.gender}
                  />
                </Field>
                <Field label='State' style={styles.field}>
                  <Select
                    onChange={onFieldChange.bind(null, 'state', true)}
                    options={criteria.allowed_states.map(s => ({ value: s, label: States[s].label }))}
                    required={true}
                    value={criteria.state}
                  />
                </Field>
              </div>
              {criteria.state === 'CA' ? (
                <Field label='County'>
                  <Select
                    invalid={errors.includes('county')}
                    onChange={onFieldChange.bind(null, 'county', true)}
                    options={[{ value: '', label: '-- Select One --' }].concat(fields_options.ca_counties.map(c => ({ value: c, label: c })))}
                    required={true}
                    value={criteria.county}
                  />
                </Field>
              ) : null}
              <Checkbox checked={criteria.tobacco} label='Tobacco User' onChange={onCheckboxChange.bind(null, 'tobacco', true)} />
              <Checkbox checked={criteria.married} label='Married/Domestic Partnership' onChange={onCheckboxChange.bind(null, 'married', true)} />
            </FieldGroup>
          </div>
          <div style={{ width: '60%' }}>
            <FieldGroup label='Quote Criteria'>
              <div style={styles.row}>
                <Field
                  label='Calculation Type'
                  style={styles.field}
                  tooltip={
                    <div>
                      <div style={styles.tooltip}>
                        <span style={styles.tooltip_label}>Premium:</span>
                        <div>Specify the premium to solve for the LTC benefit.</div>
                      </div>
                      <div style={styles.tooltip}>
                        <span style={styles.tooltip_label}>LTC Benefit (Daily, Monthly, Total):</span>
                        <div>Specify the LTC benefit to solve for the premium.</div>
                      </div>
                      <div style={styles.tooltip}>
                        <span style={styles.tooltip_label}>Specified Amount:</span>
                        <div>Input the specified amount to solve for the premium and LTC benefit.</div>
                      </div>
                    </div>
                  }
                  tooltipWidth={350}
                >
                  <Select
                    onChange={onFieldChange.bind(null, 'calculation_type', false)}
                    options={fields_options.calculation_options.map(ct => ({ value: ct, label: ct }))}
                    required={true}
                    value={criteria.calculation_type}
                  />
                </Field>
                <Field
                  label={benefitAmountTitle[criteria.calculation_type]}
                  style={styles.field}
                  tooltip={`This amount is used to determine LTC benefits and the death benefits as well as Policy Charges and Deductions from the Accumulated Value. The ${benefitAmountTitle[criteria.calculation_type]} is not the same as the Net Death Benefit.`}
                  tooltipPosition='left'
                >
                  <Input format='currency' invalid={errors.includes('amount')} onChange={onFieldChange.bind(null, 'amount', false)} required={true} value={criteria.amount} />
                </Field>
              </div>
              <div style={styles.row}>
                <Field label='Payment Schedule' style={styles.field} tooltip='This is how long the policyholder will make premium payments.'>
                  <Select
                    onChange={onFieldChange.bind(null, 'scheduled_premium', false)}
                    options={paymentSchedules.map(sp => ({ value: sp, label: sp }))}
                    required={true}
                    value={criteria.scheduled_premium}
                  />
                </Field>
                <Field label='Payment Frequency' style={styles.field} tooltip='This is how often the policyholder will make premium payments.' tooltipPosition='left'>
                  <Select onChange={onFieldChange.bind(null, 'premium_mode', false)} options={paymentFrequency.map(pf => ({ value: pf, label: pf }))} required={true} value={criteria.premium_mode} />
                </Field>
              </div>
              <div style={styles.row}>
                <Field label='Benefit Duration' style={styles.field}>
                  <PowerSelect
                    invalid={errors.includes('benefit_durations')}
                    isMulti={true}
                    onChange={onPowerSelectChange.bind(null, 'benefit_durations')}
                    options={['2', '3', '4', '5', '6', '7'].map(year => ({ value: year, label: `${year} Years` }))}
                    placeholder='Select At Least 1'
                    required={true}
                    value={criteria.benefit_durations.map(year => ({ value: year, label: `${year} Years` }))}
                  />
                </Field>
                {is_ca_ny ? null : (
                  <Field label='Lump Sum Premium' style={styles.field} tooltip='An initial amount paid in addition to the planned premium.' tooltipPosition='left'>
                    <Input format='currency' invalid={errors.includes('lump_sum_premium')} onChange={onFieldChange.bind(null, 'lump_sum_premium', false)} value={criteria.lump_sum_premium} />
                  </Field>
                )}
              </div>
              {is_ca_ny ? null : (
                <Field
                  label='Refund of Premium Option'
                  tooltip={
                    <div>
                      <div style={styles.tooltip}>
                        <span style={styles.tooltip_label}>Vested:</span>
                        <div>Refund of premium begins at 85% in year 1, then increases to 100% in year 6 (Or year 11 for 10-Pay and Pay to Age 65).</div>
                      </div>
                      <div style={styles.tooltip}>
                        <span style={styles.tooltip_label}>One Time Step-Up:</span>
                        <div>Refund of premium begins at 80% in years 1-10, then increases to 100% starting in year 11.</div>
                      </div>
                      <div style={styles.tooltip}>
                        <span style={styles.tooltip_label}>Maximum LTC Benefit:</span>
                        <div>Refund of premium amount is equal to cash surrender value. This provides the highest LTC benefits of all ROP options.</div>
                      </div>
                    </div>
                  }
                  tooltipWidth={350}
                >
                  <PowerSelect
                    invalid={errors.includes('refund_of_premiums')}
                    isMulti={true}
                    onChange={onPowerSelectChange.bind(null, 'refund_of_premiums')}
                    options={fields_options.refund_options.map(rp => ({ value: rp, label: rp }))}
                    placeholder='Select At Least 1'
                    required={true}
                    value={criteria.refund_of_premiums.map(rp => ({ value: rp, label: rp }))}
                  />
                </Field>
              )}
              <Field label='Inflation Protection' tooltip='Choosing an inflation option increases the maximum daily, monthly and lifetime benefits of the policy, at the chosen rate.'>
                <PowerSelect
                  invalid={errors.includes('inflation_benefits')}
                  isMulti={true}
                  onChange={onPowerSelectChange.bind(null, 'inflation_benefits')}
                  options={productInflations.map(pi => ({ value: pi, label: pi }))}
                  placeholder='Select At Least 1'
                  required={true}
                  value={criteria.inflation_benefits.map(pi => ({ value: pi, label: pi }))}
                />
              </Field>
              <div style={styles.row}>
                <Field label='1035 Exchange?' style={{ width: 'calc(33.3% - 5px)' }} tooltip="Select 'Yes' if the policy is being funded from another Life Insurance policy.">
                  <Select
                    onChange={onFieldChange.bind(null, 'exchange', false)}
                    options={[
                      { label: 'No', value: 'no' },
                      { label: 'Yes', value: 'yes' }
                    ]}
                    value={criteria.exchange}
                  />
                </Field>
                <Field label='Exchange Amount' style={{ width: 'calc(33.3% - 5px)' }} tooltip='This is the amount of funds being used in the 1035 exchange.' tooltipPosition='left'>
                  <Input
                    format='currency'
                    invalid={errors.includes('exchange_amount')}
                    onChange={onFieldChange.bind(null, 'exchange_amount', false)}
                    readOnly={criteria.exchange === 'no'}
                    required={criteria.exchange === 'yes'}
                    value={criteria.exchange_amount}
                  />
                </Field>
                <Field label='Existing Contract MEC?' style={{ width: 'calc(33.3% - 5px)' }}>
                  <Select
                    onChange={onFieldChange.bind(null, 'existing_contract_mec', false)}
                    options={[
                      { label: 'No', value: 'no' },
                      { label: 'Yes', value: 'yes' }
                    ]}
                    readOnly={criteria.exchange === 'no'}
                    value={criteria.existing_contract_mec}
                  />
                </Field>
              </div>
            </FieldGroup>
          </div>
        </div>
        <div style={styles.actions}>
          <Button onClick={onResetCriteria} outline={true} style={{ marginLeft: 'auto', marginRight: 10 }}>
            Reset To Defaults
          </Button>
          <Button onClick={onGetQuotes}>Get Quotes</Button>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40
      },
      criteria: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      row: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      field: {
        width: 'calc(50% - 5px)'
      },
      actions: {
        display: 'flex',
        marginTop: 40
      },
      tooltip: {
        display: 'flex',
        padding: '5px 0'
      },
      tooltip_label: {
        fontWeight: 600,
        flexShrink: 0,
        width: 100,
        marginRight: 5,
        textAlign: 'right'
      }
    };
  };
}
Criteria.contextType = LtcContext;

export default Criteria;
