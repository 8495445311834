import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import { Colors, FontWeights, FontSizes } from 'constants/Clementine';

const ProductDetails = ({ quote }) => {
  const context = React.useContext(ThemeContext);
  const styles = {
    logo: {
      height: 150,
      width: 'auto'
    },
    product_summary: {
      margin: '30px 0',
      color: Colors.GRAY.hex,
      fontSize: FontSizes.REGULAR,
      fontWeight: FontWeights.REGULAR,
      lineHeight: '20px'
    },
    feature_title: {
      marginTop: 30,
      fontSize: FontSizes.LARGE,
      fontWeight: FontWeights.MEDIUM,
      color: Colors.GRAY.hex
    },
    key_feature: {
      margin: '15px 0px 0px 10px',
      fontSize: FontSizes.REGULAR,
      fontWeight: FontWeights.REGULAR,
      color: Colors.GRAY.hex,
      display: 'flex',
      lineHeight: '1.4em'
    },
    key_check: {
      color: context.color,
      marginRight: 5,
      fontSize: 18
    }
  };

  return (
    <div style={{ padding: 40, maxWidth: 800 }}>
      <img src={quote.carrier.logo.url} style={styles.logo} />
      <div style={styles.product_summary}>{quote.product.summary}</div>
      <div style={styles.feature_title}>Key Features</div>
      {quote.product.features.map((feat, i) => {
        return (
          <div key={`${quote.id}-feature-${i}`} style={styles.key_feature}>
            <span className='mdi mdi-check' style={styles.key_check} /> {feat.label}
          </div>
        );
      })}
    </div>
  );
};

ProductDetails.propTypes = {
  quote: PropTypes.object.isRequired
};

export default ProductDetails;
