import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import Moment from 'moment';
import _intersection from 'lodash/intersection';

import Button from 'shared/Button';

import Intro from 'ltc/pages/Intro';
import Criteria from 'ltc/pages/Criteria';
import Quotes from 'ltc/pages/Quotes';

import Loader from 'shared/Loader';
import Modal from 'shared/Modal';
import ZeroState from 'shared/ZeroState';

//import { LtcContext } from 'ltc/Context';
import { ThemeContext } from 'shared/ThemeContext';

import LtcApi from 'utils/LtcApi';
import FormUtils from 'utils/Form';

import { FontSizes, FontWeights, FontFamilies } from 'constants/Clementine';
import Notification from '../components/Notifications/Toasts';
import { LtcContext } from '../components/ltc_quoter/Context';

const LTC_CONFIG = window.IXN_LTC_CONFIG || {};
const COOKIED_INFO = Cookies.getJSON('ixn_ltc_quoter') || {};

class LtcQuoter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide_intro: COOKIED_INFO.hide_intro,
      active_view: COOKIED_INFO.hide_intro ? 'criteria' : 'intro',
      modal: null,
      quote: null,
      show_age: false,
      layout: LTC_CONFIG.layout || 'producer',
      loading_quoter: true,
      loading_quotes: false,
      loading_errors: [],
      show_criteria_change_warning: false,
      quoter: null,
      criteria: {
        // Producer Info
        producer_first_name: '',
        producer_last_name: '',
        producer_email: '',
        producer_phone: '',

        // Consumer Info (NOT Cookied)
        consumer_first_name: '',
        consumer_last_name: '',
        date_of_birth: Moment().subtract(55, 'years').format('YYYY-MM-DD'),
        age: 55,
        gender: '',
        state: '',
        county: '',
        tobacco: false,
        married: false,

        // Product Info
        calculation_type: '', // TODO: check if value is allowed
        benefit_durations: [], // TODO: check if value is allowed
        inflation_benefits: [], // TODO: check if array and values are allowed
        premium_mode: '', // TODO: check if value is allowed
        scheduled_premium: '', // TODO: check if value is allowed
        refund_of_premiums: [], // TODO: check if array and values are allowed
        amount: '', // TODO: Check if value is greater than 0
        lump_sum_premium: '', // TODO: Check if value is greater than 0
        exchange: '',
        exchange_amount: '', // TODO: Check if value is greater than 0
        existing_contract_mec: ''
      },
      required_fields: [],
      errors: [],
      quotes: [],
      knocked_out_quotes: [],
      saved_quotes: [],
      theme_color: '#346094'
    };
  }

  componentDidMount = () => {
    LtcApi.loadQuoter(LTC_CONFIG.id)
      .then(quoter => {
        const ltc_client = LTC_CONFIG.client || {};
        const updated_state = {
          loading_quoter: false,
          loading_errors: [],
          required_fields: quoter.required_fields || [],
          fields_options: quoter.fields_options || [],
          hide_intro: !!quoter.hide_intro,
          active_view: quoter.hide_intro ? 'criteria' : 'intro',
          criteria: this._getDefaultCriteria(quoter, true),
          theme_color: ltc_client.theme_color || quoter.theme_color || '#346094',
          quoter
        };

        if (LTC_CONFIG.quote_link_id) {
          LtcApi.loadQuoteLink(LTC_CONFIG.quote_link_id).then(quote_link => {
            updated_state.saved_quotes = quote_link.quotes;
            updated_state.active_view = 'results';
            updated_state.criteria = Object.assign({}, updated_state.criteria, quote_link.quote_request);

            this.setState(updated_state);
          });
        } else {
          this.setState(updated_state);
        }
      })
      .catch(() => {
        this.setState({
          loading_quoter: false,
          loading_errors: [{ title: 'This quoter is not active.' }]
        });
      });
  };

  _getDefaultCriteria = (quoter, use_cookies) => {
    const ltc_client = LTC_CONFIG.client || {};
    const default_gender = ltc_client.default_gender || quoter.default_gender || '';
    const default_dob = ltc_client.default_dob || quoter.default_dob;
    const default_state = use_cookies ? COOKIED_INFO.state || ltc_client.default_state || quoter.default_state || 'OH' : ltc_client.default_state || quoter.default_state || 'OH';
    const allowed_states = LTC_CONFIG.states ? _intersection(quoter.states, LTC_CONFIG.states) : quoter.states;
    const criteria = {
      allowed_states,

      // Producer Info
      producer_first_name: use_cookies ? COOKIED_INFO.producer_first_name : '',
      producer_last_name: use_cookies ? COOKIED_INFO.producer_last_name : '',
      producer_email: use_cookies ? COOKIED_INFO.producer_email : '',
      producer_phone: use_cookies ? COOKIED_INFO.producer_phone : '',

      // Consumer Info (NOT Cookied)
      consumer_first_name: '',
      consumer_last_name: '',
      date_of_birth: default_dob && Moment(default_dob).isValid() ? Moment(default_dob).format('YYYY-MM-DD') : Moment().subtract(55, 'years').format('YYYY-MM-DD'),
      age: ltc_client.default_age || quoter.default_age || 55,
      gender: ['Male', 'Female'].includes(default_gender) ? default_gender : 'Female',
      state: allowed_states.includes(default_state) ? default_state : allowed_states[0],
      county: ltc_client.default_county || '',
      tobacco: !!ltc_client.default_tobacco || !!quoter.default_tobacco || false,
      married: !!ltc_client.default_married || !!quoter.default_married || false,

      // Product Info
      calculation_type: ltc_client.default_calculation || quoter.default_calculation || 'Premium', // TODO: check if value is allowed
      benefit_durations: ltc_client.default_benefit_durations || quoter.default_benefit_duration !== null ? [quoter.default_benefit_duration] : null || ['6'], // TODO: check if value is allowed
      inflation_benefits: ltc_client.default_inflation_protections || quoter.default_inflation_protections || [], // TODO: check if array and values are allowed
      premium_mode: ltc_client.default_payment_frequency || quoter.default_payment_frequency || 'Annual', // TODO: check if value is allowed
      scheduled_premium: ltc_client.default_payment_schedule || quoter.default_payment_schedule || 'Single Premium', // TODO: check if value is allowed
      refund_of_premiums: ltc_client.default_refund_of_premium_options || quoter.default_refund_of_premium_options || ['Maximum LTC Benefit'], // TODO: check if array and values are allowed
      amount: ltc_client.default_amount || (quoter.default_amount ? quoter.default_amount.toString() : '100000'), // TODO: Check if value is greater than 0
      lump_sum_premium: ltc_client.default_lump_sum_premium || quoter.default_lump_sum_premium || '0', // TODO: Check if value is greater than 0
      exchange: ltc_client.default_exchange || quoter.default_exchange ? 'yes' : 'no',
      exchange_amount: ltc_client.default_exchange_amount || quoter.default_rollover_amount || '0', // TODO: Check if value is greater than 0
      existing_contract_mec: ltc_client.default_existing_contract_mec ? 'yes' : 'no'
    };

    return criteria;
  };

  _hideIntro = () => {
    const cookied_info = Cookies.getJSON('ixn_ltc_quoter') || {};

    Cookies.set('ixn_ltc_quoter', Object.assign({}, cookied_info, { hide_intro: true }), { domain: window.location.hostname });

    this.setState({
      hide_intro: true,
      active_view: 'criteria'
    });
  };

  _toggleView = active_view => {
    this.setState({ active_view });
  };

  _toggleAge = show_age => {
    this.setState({ show_age });
  };

  _handleResetCriteria = () => {
    const criteria = this._getDefaultCriteria(this.state.quoter, false);

    if (this.state.saved_quotes.length) {
      this.setState({
        show_criteria_change_warning: true,
        pending_criteria_change: criteria
      });
    } else {
      this.setState({ criteria });
    }
  };

  _handleFieldChange = (name, show_warning, e) => {
    const value = e.target.value;
    const criteria = Object.assign({}, this.state.criteria, {
      [name]: value
    });

    if (name === 'state' && ['CA', 'NY'].includes(value)) {
      criteria.scheduled_premium = ['Pay to Age 65', 'Pay to Age 100'].includes(criteria.scheduled_premium) ? 'Single Premium' : criteria.scheduled_premium;
      criteria.lump_sum_premium = '0';
      criteria.refund_of_premiums = ['Vested'];
      criteria.inflation_benefits = [];
    }

    if (name === 'scheduled_premium' && value === 'Single Premium') {
      criteria.premium_mode = 'Annual';
    }

    if (name === 'exchange' && value === 'no') {
      criteria.exchange_amount = '0';
      criteria.exchange_contract_mec = 'no';
    }

    if (value !== this.state.criteria[name] && show_warning && this.state.saved_quotes.length) {
      this.setState({
        show_criteria_change_warning: true,
        pending_criteria_change: criteria
      });
    } else {
      this.setState({ criteria });
    }
  };

  _handlePowerSelectChange = (name, selected) => {
    const criteria = Object.assign({}, this.state.criteria, { [name]: selected ? selected.map(s => s.value) : [] });

    if (name === 'refund_of_premiums' && (!selected || !selected.includes('Maximum LTC Benefit'))) {
      criteria.inflation_benefits = criteria.inflation_benefits.filter(ib => ib !== 'US Medical Care Inflation');
    }

    this.setState({ criteria });
  };

  _handleDobChange = date_of_birth => {
    const criteria = Object.assign({}, this.state.criteria, { date_of_birth });

    if (date_of_birth !== this.state.criteria.date_of_birth && this.state.saved_quotes.length) {
      this.setState({
        show_criteria_change_warning: true,
        pending_criteria_change: criteria
      });
    } else {
      this.setState({ criteria });
    }
  };

  _handleCheckboxChange = (name, show_warning, e) => {
    const criteria = Object.assign({}, this.state.criteria, { [name]: e.target.checked });

    if (e.target.checked !== this.state.criteria[name] && show_warning && this.state.saved_quotes.length) {
      this.setState({
        show_criteria_change_warning: true,
        pending_criteria_change: criteria
      });
    } else {
      this.setState({ criteria });
    }
  };
  _handleShowQuoteActions = quote => {
    this.setState({
      modal: 'actions',
      quote
    });
  };

  _handleConfirmCriteriaChange = confirmed => {
    if (confirmed) {
      this.setState({
        show_criteria_change_warning: false,
        criteria: this.state.pending_criteria_change,
        pending_criteria_change: null,
        saved_quotes: []
      });
    } else {
      this.setState({
        show_criteria_change_warning: false,
        pending_criteria_change: null
      });
    }
  };

  _handleSaveClick = id => {
    const quotes = this.state.quotes.filter(q => q.id !== id);
    const quote = this.state.quotes.find(q => q.id === id);
    const saved_quotes = [...this.state.saved_quotes].concat([quote]);

    this.setState({
      quotes,
      saved_quotes
    });
  };

  _handleDeleteClick = id => {
    const saved_quotes = this.state.saved_quotes.filter(q => q.id !== id);
    const quote = this.state.saved_quotes.find(q => q.id === id);
    const quotes = [...this.state.quotes].concat([quote]);

    this.setState({
      quotes,
      saved_quotes
    });
  };

  _getQuotes = () => {
    const { criteria, required_fields } = this.state;
    const errors = [];
    const required_fields_actions = [
      { name: 'producer_first_name', type: 'string' },
      { name: 'producer_last_name', type: 'string' },
      { name: 'producer_email', type: 'email' },
      { name: 'producer_phone', type: 'phone' },
      { name: 'consumer_first_name', type: 'string' },
      { name: 'consumer_last_name', type: 'string' },
      { name: 'amount', type: 'string', valid: val => parseInt(val, 10) > 0 },
      { name: 'benefit_durations', type: 'array' },
      { name: 'refund_of_premiums', type: 'array' },
      { name: 'inflation_benefits', type: 'array' },
      { name: 'county', type: 'string' },
      { name: 'date_of_birth', type: 'string' },
      { name: 'age', type: 'number' }
    ];

    // if email is entered, check for correct email format even if not required
    if (criteria.producer_email && criteria.producer_email.length && !FormUtils._validateEmail(criteria.producer_email)) {
      errors.push('producer_email');
    }

    // default required fields
    required_fields.push('amount', 'benefit_durations', 'refund_of_premiums', 'inflation_benefits');

    if (criteria.state === 'CA') {
      required_fields.push('county');
    }

    if (this.state.show_age) {
      required_fields.push('age');
    } else {
      required_fields.push('date_of_birth');
    }

    required_fields.forEach(field_name => {
      const field = required_fields_actions.find(obj => {
        return obj.name === field_name;
      });

      if (field) {
        const name = field.name;
        const value = criteria[name];

        if (!value) {
          errors.push(name);

          return;
        }

        switch (field.type) {
          case 'string':
            if (!value.length) {
              errors.push(name);
            }
            break;
          case 'phone':
            if (value.length !== 10) {
              errors.push(name);
            }
            break;
          case 'number':
            if (value.length < 1) {
              errors.push(name);
            }
            break;
          case 'email':
            if (!FormUtils._validateEmail(value)) {
              errors.push(name);
            }
            break;
          case 'array':
            if (Array.isArray(value) && !value.length) {
              errors.push(name);
            }
        }

        if (field.valid && !field.valid(value)) {
          errors.push(name);
        }
      }
    });

    if (errors.length) {
      this.setState({ errors });
    } else {
      this.setState({ errors, loading_quotes: true });

      const quote_params = {
        gender: criteria.gender,
        state: criteria.state,
        county: criteria.county,
        tobacco: criteria.tobacco,
        married: criteria.married,
        calculation_type: criteria.calculation_type,
        amount: parseInt(criteria.amount, 10),
        scheduled_premium: criteria.scheduled_premium,
        refund_of_premiums: criteria.refund_of_premiums,
        lump_sum_premium: parseInt(criteria.lump_sum_premium, 10),
        premium_mode: criteria.premium_mode,
        benefit_durations: criteria.benefit_durations,
        inflation_benefits: criteria.inflation_benefits,
        exchange: criteria.exchange === 'yes',
        exchange_amount: parseInt(criteria.exchange_amount, 10),
        existing_contract_mec: criteria.exchange_contract_mec === 'yes',
        agent_email: criteria.producer_email,
        agent_phone: criteria.producer_phone,
        agent_first_name: criteria.producer_first_name,
        agent_last_name: criteria.producer_last_name,
        consumer_first_name: criteria.consumer_first_name,
        consumer_last_name: criteria.consumer_last_name,
        carrier_ids: [],
        product_ids: []
      };

      if (this.state.show_age) {
        quote_params.age = criteria.age;
      } else {
        quote_params.date_of_birth = criteria.date_of_birth;
      }

      if (criteria.scheduled_premium === 'Single Premium') {
        quote_params.premium_mode = 'Annual';
      }

      LtcApi.getQuotes(quote_params).then(results => {
        const cookied_info = Cookies.getJSON('ixn_ltc_quoter') || {};
        const quotes = [];
        const knocked_out_quotes = [];

        results.forEach(q => (q.is_knockout ? knocked_out_quotes.push(q) : quotes.push(q)));

        Cookies.set(
          'ixn_ltc_quoter',
          Object.assign({}, cookied_info, {
            producer_email: criteria.producer_email,
            producer_phone: criteria.producer_phone,
            producer_first_name: criteria.producer_first_name,
            producer_last_name: criteria.producer_last_name,
            state: criteria.state,
            county: criteria.county
          }),
          { domain: window.location.hostname }
        );

        this.setState({
          loading_quotes: false,
          active_view: 'results',
          quotes,
          knocked_out_quotes
        });
      });
    }
  };

  render() {
    const {
      active_view,
      criteria,
      errors,
      fields_options,
      knocked_out_quotes,
      layout,
      loading_errors,
      loading_quoter,
      loading_quotes,
      quoter,
      quotes,
      required_fields,
      saved_quotes,
      show_age,
      show_criteria_change_warning,
      theme_color
    } = this.state;
    const styles = this.styles();
    const context = {
      color: theme_color,
      criteria,
      errors,
      fields_options,
      knocked_out_quotes,
      layout,
      quoter,
      quotes,
      required_fields,
      saved_quotes,
      show_age
    };

    return (
      <ThemeContext.Provider value={{ color: theme_color }}>
        <LtcContext.Provider value={context}>
          <Notification color={theme_color} message='Welcome to LTC Quoter' />
          {loading_quoter ? (
            <Loader />
          ) : (
            <React.Fragment>
              {loading_errors.length ? (
                <ZeroState
                  icon='mdi-alert-circle-outline'
                  message={
                    <div>
                      {loading_errors[0].title} Please contact IXN Support at <a href='mailto:support@ixntech.com'>support@ixntech.com</a>.
                    </div>
                  }
                />
              ) : (
                <div style={styles.ltc_window}>
                  {show_criteria_change_warning ? (
                    <Modal
                      buttons={[
                        { children: 'No', outline: true, onClick: this._handleConfirmCriteriaChange.bind(null, false), style: { margin: '0 10px 0 auto' } },
                        { children: 'Yes, clear out my saved quotes', onClick: this._handleConfirmCriteriaChange.bind(null, true) }
                      ]}
                      maxWidth={600}
                      onClose={this._handleConfirmCriteriaChange.bind(null, false)}
                      title='Are you sure?'
                    >
                      <div style={{ padding: 40, fontSize: FontSizes.XLARGE, textAlign: 'center', lineHeight: '1.5em' }}>
                        You are attempting to change the insured's personal information. Doing so will clear out your saved quotes. Are you sure you want to make this change?
                      </div>
                    </Modal>
                  ) : null}

                  {layout !== 'consumer' ? (
                    <div className='ltc-status-bar' style={styles.top_bar}>
                      <div style={styles.title}>{quoter.name}</div>
                      <Button icon={active_view === 'criteria' ? 'close' : 'tune-vertical'} onClick={this._toggleView.bind(null, active_view === 'criteria' ? 'results' : 'criteria')}>
                        {active_view === 'criteria' ? 'Close' : 'Case Design'}
                      </Button>
                    </div>
                  ) : null}

                  {loading_quotes ? (
                    <div style={{ minHeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Loader />
                    </div>
                  ) : (
                    <div style={styles.content}>
                      {active_view === 'intro' ? <Intro onDontShowClick={this._hideIntro} onViewCriteriaClick={this._toggleView.bind(null, 'criteria')} /> : null}

                      {active_view === 'criteria' ? (
                        <Criteria
                          onCheckboxChange={this._handleCheckboxChange}
                          onDobChange={this._handleDobChange}
                          onFieldChange={this._handleFieldChange}
                          onGetQuotes={this._getQuotes}
                          onPowerSelectChange={this._handlePowerSelectChange}
                          onResetCriteria={this._handleResetCriteria}
                          onToggleAge={this._toggleAge}
                        />
                      ) : null}

                      {active_view === 'results' ? <Quotes onDeleteClick={this._handleDeleteClick} onSaveClick={this._handleSaveClick} /> : null}
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </LtcContext.Provider>
      </ThemeContext.Provider>
    );
  }

  styles = () => {
    return {
      ltc_window: {
        border: '1px solid #E5E5E5',
        borderRadius: 5,
        background: '#fff'
      },
      top_bar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E5E5E5',
        padding: 20
      },
      title: {
        fontFamily: FontFamilies.ACCENT,
        fontSize: FontSizes.XLARGE,
        lineHeight: '1',
        fontWeight: FontWeights.MEDIUM
      }
    };
  };
}

ReactDOM.render(<LtcQuoter />, document.getElementById('ltc-quoter'));
