import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'shared/Modal';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';
import { LtcContext } from '../Context';

const LTC_CONFIG = window.IXN_LTC_CONFIG || {};
const action_defaults = {
  firelight: {
    icon: 'mdi-file-document-edit-outline',
    label: 'FireLight'
  }
};
// Never shown in FireLight
const fl_disabled_action_types = ['applicant', 'firelight', 'psg_eapp'];
// Hidden in FireLight if FL apply action is available
const fl_restricted_action_types = ['anico', 'nmb_term_eticket', 'nmb_whole_life_eticket', 'paclife', 'protective', 'request_forms', 'sbli', 'standard_packet'];

class QuoteActions extends React.Component {
  static propTypes = {
    onAction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props);

    this.state = {
      loading: false,
      hovering_id: null,
      agent_email: context.agent?.email || '',
      agent_name: `${context.agent?.first_name} ${context.agent?.last_name}`,
      agent_phone_number: context.agent?.phone || '',
      agent_license_number: context.agent?.insurance_license_number || '',
      client_name: `${context.client?.first_name} ${context.client?.last_name}`
    };
  }

  _handleHover = hovering_id => {
    this.setState({ hovering_id });
  };

  _fireLightActionIsValid = quote_action => {
    const embed_config_quote_actions = this.context.ltc?.embed_config_quote_actions;
    const fl_apply_quote_action = embed_config_quote_actions?.find(action => action.type === 'fl_apply');

    if (fl_disabled_action_types?.includes(quote_action.action_type)) {
      return false;
    } else if (fl_apply_quote_action && fl_apply_quote_action.enabled_quote_guids?.includes(this.props.quote.guid) && fl_restricted_action_types?.includes(quote_action.action_type)) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { onAction, onClose, quote } = this.props;
    const { loading, hovering_id } = this.state;
    const styles = this.styles();

    return (
      <Modal loading={loading} maxWidth={800} onClose={onClose} title='Select An Action'>
        <div style={styles.content}>
          <div style={styles.wrapper}>
            {this.context.ltc?.embed_config_quote_actions
              ? this.context.ltc?.embed_config_quote_actions.map((action, index) => {
                  if (action.enabled_quote_guids.includes(quote.guid)) {
                    return (
                      <div
                        key={action.id || index}
                        onClick={QUOTER_CONFIG.embed_context === 'fire_light' ? this._handleFireLightQuoteAction.bind(null, action.on_click, quote) : action.on_click.bind(null, quote)}
                        onMouseEnter={this._handleHover.bind(null, action.id || index)}
                        onMouseLeave={this._handleHover.bind(null, null)}
                        style={Object.assign({}, styles.box_button, (hovering_id === action.id || hovering_id === index) && styles.box_button_selected)}
                      >
                        <i className={`mdi mdi-${action.icon}`} style={styles.box_button_icon} />
                        <div style={styles.box_button_label}>{action.label}</div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              : null}

            {this.context.quoter?.quote_actions.map((action, index) => {
              if (LTC_CONFIG.embed_context !== 'fire_light' || this._fireLightActionIsValid(action)) {
                return (
                  <div
                    key={action.id || index}
                    onClick={onAction.bind(null, action)}
                    onMouseEnter={this._handleHover.bind(null, action.id || index)}
                    onMouseLeave={this._handleHover.bind(null, null)}
                    style={Object.assign({}, styles.box_button, (hovering_id === action.id || hovering_id === index) && styles.box_button_selected)}
                  >
                    {action.icon && action.icon.url ? (
                      <div style={Object.assign({ backgroundImage: 'url(' + action.icon.url + ')' }, styles.box_button_image)} />
                    ) : (
                      <i className={`mdi ${action_defaults[action.action_type].icon}`} style={styles.box_button_icon} />
                    )}
                    <div style={styles.box_button_label}>{action.label.length ? action.label : action_defaults[action.action_type].label}</div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'wrap'
      },
      box_button: {
        width: 120,
        height: 120,
        margin: '0 10px 20px',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer'
      },
      box_button_selected: {
        border: `2px solid ${Colors.GREEN.hex}`
      },
      box_button_image: {
        height: 40,
        width: 40,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      },
      box_button_icon: {
        fontSize: 40
      },
      box_button_label: {
        marginTop: 10,
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM
      }
    };
  };
}

QuoteActions.contextType = LtcContext;

export default QuoteActions;
