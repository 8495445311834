import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'shared/Modal';

import Illustration from 'ltc/components/Illustration';
import ProductDetails from 'ltc/components/ProductDetails';
import ProductResources from 'ltc/components/ProductResources';
import QuoteDetails from 'ltc/components/QuoteDetails';

import { FontSizes, FontWeights } from 'constants/Clementine';
import { LtcContext } from '../Context';

class QuoteActionModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    quickPdf: PropTypes.bool,
    quote: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      active_tab: props.quickPdf ? 'illustration' : 'quote_details'
    };
  }

  _handleTabClick = active_tab => {
    this.setState({ active_tab });
  };

  render() {
    const { layout } = this.context;
    const { active_tab } = this.state;
    const { onClose, quickPdf, quote } = this.props;
    const styles = this.styles();
    const actions = [
      { name: 'quote_details', label: 'Quote Details', icon: 'mdi-cash-usd' },
      { name: 'product_details', label: 'Product Details', icon: 'mdi-package' }
    ];

    if (layout !== 'consumer') {
      actions.push({ name: 'resources', label: 'Resources', icon: 'mdi-archive' }, { name: 'illustration', label: 'Illustration', icon: 'mdi-file-pdf-box' });
    }

    return (
      <Modal maxWidth={1200} onClose={onClose} title={`${quote.carrier.name} - ${quote.product.name}`}>
        <div style={styles.container}>
          <div style={styles.tabs}>
            {actions.map(t => {
              const is_active = active_tab === t.name;

              return (
                <div key={t.name} onClick={this._handleTabClick.bind(null, t.name)} style={Object.assign({}, styles.tab, is_active && styles.tab_active)}>
                  <i className={`mdi ${t.icon}`} style={Object.assign({}, styles.tab_icon, is_active && styles.tab_icon_active)} />
                  {t.label}
                </div>
              );
            })}
          </div>
          <div style={styles.content}>
            {active_tab === 'quote_details' ? <QuoteDetails quote={quote} /> : null}

            {active_tab === 'product_details' ? <ProductDetails quote={quote} /> : null}

            {active_tab === 'resources' ? <ProductResources quote={quote} /> : null}

            {active_tab === 'illustration' ? <Illustration quickPdf={quickPdf} quote={quote} /> : null}
          </div>
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      container: {
        display: 'flex'
      },
      tabs: {
        backgroundColor: '#f9f9f9',
        padding: '10px 0 10px 10px',
        width: 200,
        flexShrink: 0,
        borderRight: '1px solid #e5e5e5'
      },
      tab: {
        padding: 8,
        borderRadius: '4px 0 0 4px',
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.BOLD,
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        cursor: 'pointer'
      },
      tab_icon: {
        borderRadius: 4,
        backgroundColor: '#777',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        marginRight: 10,
        padding: 4,
        fontSize: 18
      },
      tab_active: {
        backgroundColor: '#fff',
        border: '1px solid #e5e5e5',
        borderRightColor: '#fff',
        marginRight: -1
      },
      tab_icon_active: {
        color: this.context.color,
        backgroundColor: `${this.context.color}4d`
      },
      content: {
        flex: 1,
        width: 1200,
        height: 'calc(100vh - 71px)'
      }
    };
  };
}

QuoteActionModal.contextType = LtcContext;

export default QuoteActionModal;
