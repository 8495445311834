import Axios from 'axios';
import Cookies from 'js-cookie';

import AppConfig from 'config/App';

const getCriteriaCombos = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));

const token = window.IXN_LTC_CONFIG.id;
const request = () => {
  const ixn_auth = Cookies.getJSON('ixn') || {};

  return Axios.create({
    baseURL: AppConfig.API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'access-token': ixn_auth.access_token,
      client: ixn_auth.client,
      uid: ixn_auth.uid
    }
  });
};

const LtcApi = {
  loadQuoter(id) {
    return request()
      .get(`/v1/ltc_quoters/${id}`, {
        headers: {
          'LTC-QUOTER-TOKEN': token
        }
      })
      .then(response => response.data);
  },

  createQuoteLink(ltc_quote_ids) {
    return request()
      .post(
        '/v1/ltc_quote_links',
        { ltc_quote_ids },
        {
          headers: {
            'LTC-QUOTER-TOKEN': token
          }
        }
      )
      .then(response => response.data.id);
  },

  loadQuoteLink(id) {
    return request()
      .get(`/v1/ltc_quote_links/${id}`, {
        headers: {
          'LTC-QUOTER-TOKEN': token
        }
      })
      .then(response => response.data);
  },
  createEApp({ ltc_quoter_id, params }) {
    return request().post('/v1/e_apps', params, {
      headers: {
        'ltc-quoter-id': ltc_quoter_id
      }
    });
  },

  updateEApp({ id, ltc_quoter_id, params }) {
    return request().put(`/v1/e_apps/${id}`, params, {
      headers: {
        'ltc-quoter-id': ltc_quoter_id
      }
    });
  },

  sendEAppLink(xml_id, params) {
    return request().post(`/v1/e_apps/${xml_id}/send_link`, params);
  },

  checkNewMemberEmail(email) {
    return request().post('/v1/member_exists', { email });
  },
  login(params) {
    return request().post('/v1/members/login', params);
  },

  logout() {
    return request().delete('/v1/members/logout');
  },

  loadMember(member_id) {
    return request().get(`/v1/members/${member_id}`);
  },

  register(params) {
    return request().post('/v1/members', params);
  },

  sendFeedback(params) {
    return request().post('/v1/feedback', params);
  },

  getQuotes(params) {
    const requests = [];
    const combos = getCriteriaCombos(params.benefit_durations, params.inflation_benefits, params.refund_of_premiums);

    combos.forEach(combo => {
      const benefit_duration = combo[0];
      const inflation_benefit = combo[1];
      const refund_of_premium = combo[2];
      const payload = Object.assign({}, params);

      payload.benefit_duration = parseInt(benefit_duration, 10);
      payload.refund_of_premium = refund_of_premium;

      if (inflation_benefit !== 'None') {
        payload.inflation_benefit = inflation_benefit;
      }

      requests.push(
        request().post('/v1/ltc_quote_requests/', payload, {
          headers: {
            'LTC-QUOTER-TOKEN': token
          }
        })
      );
    });

    return Promise.all(requests).then(responses => responses.map(r => r.data.quotes).flat());
  },

  createIllustration(params) {
    return request()
      .post('/v1/ltc_illustrations/', params, {
        headers: {
          'LTC-QUOTER-TOKEN': token
        }
      })
      .then(response => response.data);
  },

  emailIllustration(id, params) {
    return request()
      .post(`v1/ltc_illustrations/${id}/email`, params, {
        headers: {
          'LTC-QUOTER-TOKEN': token
        }
      })
      .then(response => response.data);
  }
};

export default LtcApi;
