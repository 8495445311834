import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';

import { Colors, FontSizes } from 'constants/Clementine';

const QuoteDetails = ({ quote }) => {
  const styles = {
    table: {
      border: '1px solid #e5e5e5',
      borderRadius: 4,
      fontSize: FontSizes.REGULAR,
      color: Colors.GRAY.hex,
      width: '100%'
    },
    table_label: {
      backgroundColor: '#f9f9f9',
      padding: '10px 15px',
      textAlign: 'right'
    },
    table_value: {
      padding: '10px 15px'
    }
  };

  return (
    <div style={{ padding: 40, maxWidth: 800 }}>
      <table style={styles.table}>
        <tbody>
          {[
            { name: 'age', label: 'Age/Date of Birth', format: v => v || quote.date_of_birth },
            { name: 'gender', label: 'Gender' },
            { name: 'tobacco', label: 'Tobacco User', format: v => (v ? 'Yes' : 'No') },
            { name: 'married', label: 'Married/Domestic Partnership', format: v => (v ? 'Yes' : 'No') },
            { name: 'state', label: 'State', format: v => (v === 'CA' ? `${v} (${quote.county})` : v) },
            { name: 'inflation_benefit', label: 'Inflation Protection', format: v => v || 'None' },
            { name: 'refund_of_premium', label: 'Refund of Premium' },
            { name: 'lump_sum_premium', label: 'Lump Sum Premium', format: v => Numeral(v).format('$0,00') },
            { name: 'premium_mode', label: 'Premium Schedule' },
            { name: 'scheduled_premium', label: 'Payment Frequency' },
            { name: 'premium', label: 'Premium', format: v => Numeral(v).format('$0,00') },
            { name: 'total_benefit', label: 'Total LTC Benefit', format: v => Numeral(v).format('$0,00') },
            { name: 'total_benefit_age_80', label: 'Total LTC Benefit (Age 80)', format: v => Numeral(v).format('$0,00') },
            { name: 'max_monthly_benefit', label: 'Maximum Monthly Benefit', format: v => Numeral(v).format('$0,00') },
            { name: 'max_monthly_benefit_age_80', label: 'Maximum Monthly Benefit (Age 80)', format: v => Numeral(v).format('$0,00') },
            { name: 'exchange_amount', label: '1035 Exchange Amount', format: v => Numeral(v).format('$0,00') },
            { name: 'specified_amount', label: 'Specified Amount', format: v => Numeral(v).format('$0,00') },
            { name: 'guaranteed_min_death_benefit', label: 'Guaranteed Min. Death Benefit', format: v => Numeral(v).format('$0,00') },
            { name: 'net_death_benefit', label: 'Net Death Benefit', format: v => Numeral(v).format('$0,00') },
            { name: 'guaranteed_interest_rate', label: 'Guar. Interest Rate', format: v => Numeral(v / 100).format('0.00%') }
          ].map(r => (
            <tr key={r.name}>
              <td style={styles.table_label}>{r.label}</td>
              <td style={styles.table_value}>{r.format ? r.format(quote[r.name]) : quote[r.name]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

QuoteDetails.propTypes = {
  quote: PropTypes.object.isRequired
};

export default QuoteDetails;
