import PropTypes from 'prop-types';
import React from 'react';
import Cookies from 'js-cookie';

import Button from 'shared/Button';
import Input from 'shared/Input';
import Field from 'shared/Field';
import FieldGroup from 'shared/FieldGroup';
import Loader from 'shared/Loader';
import Select from 'shared/Select';

import { FontSizes, FontWeights, States } from 'constants/Clementine';

import FormUtils from 'utils/Form';
import LtcApi from 'utils/LtcApi';
import { LtcContext } from '../Context';

class Illustration extends React.Component {
  static propTypes = {
    quickPdf: PropTypes.bool,
    quote: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    const { criteria } = context;

    this.state = {
      loading: false,
      current_view: 'additional_information',
      pdf_url: null,
      illustration_id: null,

      errors: [],

      consumer_first_name: criteria.consumer_first_name || '',
      consumer_last_name: criteria.consumer_last_name || '',
      producer_first_name: criteria.producer_first_name || '',
      producer_last_name: criteria.producer_last_name || '',
      producer_phone: criteria.producer_phone || '',
      producer_email: criteria.producer_email || '',
      producer_address: criteria.producer_address || '',
      producer_city: criteria.producer_city || '',
      producer_state: criteria.producer_state || '',
      producer_zip: criteria.producer_zip || '',
      broker_name: criteria.broker_name || '',

      to: '',
      cc: criteria.producer_email || '',
      subject: '',
      message: ''
    };
  }

  componentDidMount() {
    if (this.props.quickPdf) {
      this._generatePdf();
    }
  }

  _toggleView = current_view => {
    this.setState({
      current_view,
      errors: []
    });
  };

  _handleFieldChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  _generatePdf = () => {
    const { quote } = this.props;
    const {
      consumer_first_name,
      consumer_last_name,
      producer_first_name,
      producer_last_name,
      producer_phone,
      producer_email,
      producer_address,
      producer_city,
      producer_state,
      producer_zip,
      broker_name
    } = this.state;

    this.setState({
      loading: true
    });

    const cookied_info = Cookies.getJSON('ixn_ltc_quoter') || {};

    Cookies.set(
      'ixn_ltc_quoter',
      Object.assign({}, cookied_info, {
        producer_first_name,
        producer_last_name,
        producer_phone,
        producer_email,
        producer_address,
        producer_city,
        producer_state,
        producer_zip,
        broker_name
      }),
      { domain: window.location.hostname }
    );

    LtcApi.createIllustration({
      ltc_quote_id: quote.id,
      consumer_first_name,
      consumer_last_name,
      producer_first_name,
      producer_last_name,
      producer_phone,
      producer_email,
      producer_address,
      producer_city,
      producer_state,
      producer_zip,
      broker_name
    }).then(response => {
      this.setState({
        current_view: 'illustration',
        illustration_id: response.id,
        pdf_url: response.pdf_url,
        loading: false
      });
    });
  };

  _sendEmail = () => {
    const errors = [];
    const { illustration_id, to, cc, subject, message } = this.state;

    if (!to || !FormUtils._validateEmail(to)) {
      errors.push('to');
    }

    if (cc && !FormUtils._validateEmail(cc)) {
      errors.push('cc');
    }

    if (!subject) {
      errors.push('subject');
    }

    if (!message) {
      errors.push('message');
    }

    if (errors.length) {
      this.setState({ errors });
    } else {
      this.setState({
        loading: true
      });

      LtcApi.emailIllustration(illustration_id, { to, cc, subject, message }).then(() => {
        this.setState({
          current_view: 'success',
          errors: [],
          loading: false
        });
      });
    }
  };

  render() {
    const {
      consumer_first_name,
      consumer_last_name,
      producer_first_name,
      producer_last_name,
      producer_phone,
      producer_email,
      producer_address,
      producer_city,
      producer_state,
      producer_zip,
      broker_name,
      loading,
      current_view,
      pdf_url,
      errors,
      to,
      cc,
      subject,
      message
    } = this.state;
    const styles = this.styles();
    const titles = {
      additional_information: 'Enter Additional Information',
      illustration: 'View Illustration',
      email: 'Email Illustration',
      success: ''
    };
    const buttons = {
      additional_information: [
        {
          disabled: loading,
          onClick: loading ? null : this._generatePdf,
          style: { marginLeft: 'auto' },
          children: loading ? 'Creating Illustration...' : 'Create Illustration'
        }
      ],
      illustration: [
        {
          outline: true,
          onClick: this._toggleView.bind(null, 'additional_information'),
          style: { margin: '0 10px 0 auto' },
          children: 'Edit Producer Information'
        },
        {
          onClick: this._toggleView.bind(null, 'email'),
          children: 'Email'
        }
      ],
      email: [
        {
          outline: true,
          onClick: this._toggleView.bind(null, 'illustration'),
          style: { margin: '0 10px 0 auto' },
          children: 'Back To Illustration'
        },
        {
          onClick: this._sendEmail,
          children: 'Send Email'
        }
      ],
      success: []
    };

    return (
      <div style={styles.container}>
        {loading ? (
          <div style={styles.loader}>
            <Loader />
          </div>
        ) : null}

        <div style={styles.title}>{titles[current_view]}</div>

        {current_view === 'additional_information' ? (
          <div style={styles.content}>
            <div style={styles.info_wrapper}>
              <div style={{ width: '48%' }}>
                <FieldGroup label='Producer Information'>
                  <div style={styles.row}>
                    <Field label='First Name' style={{ width: '38%' }}>
                      <Input onChange={this._handleFieldChange.bind(null, 'producer_first_name')} value={producer_first_name} />
                    </Field>
                    <Field label='Last Name' style={{ width: '58%' }}>
                      <Input onChange={this._handleFieldChange.bind(null, 'producer_last_name')} value={producer_last_name} />
                    </Field>
                  </div>
                  <Field label='Email'>
                    <Input onChange={this._handleFieldChange.bind(null, 'producer_email')} value={producer_email} />
                  </Field>
                  <Field label='Phone'>
                    <Input format='phone' onChange={this._handleFieldChange.bind(null, 'producer_phone')} value={producer_phone} />
                  </Field>
                </FieldGroup>
                <FieldGroup label='Insured Information' style={{ marginTop: 30 }}>
                  <div style={styles.row}>
                    <Field label='First Name' style={{ width: '38%' }}>
                      <Input onChange={this._handleFieldChange.bind(null, 'consumer_first_name')} value={consumer_first_name} />
                    </Field>
                    <Field label='Last Name' style={{ width: '58%' }}>
                      <Input onChange={this._handleFieldChange.bind(null, 'consumer_last_name')} value={consumer_last_name} />
                    </Field>
                  </div>
                </FieldGroup>
              </div>

              <div style={{ width: '48%' }}>
                <FieldGroup label='Agency Information'>
                  <Field label='Name'>
                    <Input onChange={this._handleFieldChange.bind(null, 'broker_name')} value={broker_name} />
                  </Field>
                  <Field label='Address'>
                    <Input onChange={this._handleFieldChange.bind(null, 'producer_address')} value={producer_address} />
                  </Field>
                  <div style={styles.row}>
                    <Field label='City' style={{ width: '58%' }}>
                      <Input onChange={this._handleFieldChange.bind(null, 'producer_city')} value={producer_city} />
                    </Field>
                    <Field label='State' style={{ width: '38%' }}>
                      <Select onChange={this._handleFieldChange.bind(null, 'producer_state')} options={[{ value: '', label: '--' }].concat(Object.values(States))} value={producer_state} />
                    </Field>
                  </div>
                  <Field label='Zip Code'>
                    <Input onChange={this._handleFieldChange.bind(null, 'producer_zip')} value={producer_zip} />
                  </Field>
                </FieldGroup>
              </div>
            </div>
          </div>
        ) : null}

        {current_view === 'illustration' ? <iframe src={pdf_url} style={styles.iframe} /> : null}

        {current_view === 'email' ? (
          <div style={styles.content}>
            <div style={styles.row}>
              <Field label='To' style={{ width: '48%' }}>
                <Input invalid={errors.includes('to')} onChange={this._handleFieldChange.bind(null, 'to')} value={to} />
              </Field>
              <Field label='CC' style={{ width: '48%' }}>
                <Input invalid={errors.includes('cc')} onChange={this._handleFieldChange.bind(null, 'cc')} value={cc} />
              </Field>
            </div>
            <Field label='Subject'>
              <Input invalid={errors.includes('subject')} onChange={this._handleFieldChange.bind(null, 'subject')} value={subject} />
            </Field>
            <Field label='Message'>
              <Input invalid={errors.includes('message')} onChange={this._handleFieldChange.bind(null, 'message')} style={{ height: 200 }} type='textarea' value={message} />
            </Field>
          </div>
        ) : null}

        {current_view === 'success' ? (
          <div style={styles.content}>
            <div style={styles.success_wrapper}>
              <div style={styles.success_title}>Success!</div>
              <div style={styles.success_description}>This illustration has been successfully emailed.</div>
              <Button inline={true} onClick={this._toggleView.bind(null, 'illustration')}>
                Back to Illustration
              </Button>
            </div>
          </div>
        ) : null}

        <div style={styles.buttons}>
          {buttons[current_view].map((b, i) => (
            <Button key={`button-${i}`} {...b} />
          ))}
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative'
      },
      title: {
        padding: '30px 30px 10px 30px',
        fontSize: FontSizes.XLARGE
      },
      content: {
        padding: 40,
        flex: 1
      },
      loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.9)',
        zIndex: 1
      },
      info_wrapper: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      success_wrapper: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60%',
        margin: '0 auto',
        paddingBottom: 80,
        height: '100%'
      },
      success_title: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      success_description: {
        fontSize: FontSizes.MEDIUM,
        color: '#777',
        marginBottom: 40,
        lineHeight: '1.4em'
      },
      loading: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(255,255,255,0.9)',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      row: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      iframe: {
        flex: 1,
        margin: '20px 30px 30px',
        border: 'none'
      },
      buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 20,
        backgroundColor: '#fff',
        boxShadow: '0px -10px 30px -15px rgba(0,0,0,0.2)',
        borderTop: '1px solid #e5e5e5',
        position: 'sticky',
        bottom: 0
      }
    };
  };
}

Illustration.contextType = LtcContext;

export default Illustration;
