import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';

import Button from 'shared/Button';
import Tooltip from 'shared/Tooltip';

import QuoteActionModal from 'ltc/components/QuoteActionModal';
import ShareModal from 'ltc/components/ShareModal';

//import { LtcContext } from 'ltc/Context';

import { FontSizes, FontWeights } from 'constants/Clementine';
import QuoteActions from '../components/QuoteActions';
import FirelightEappModal from '../components/FirelightEappModal';
import { LtcContext } from '../Context';

class Quotes extends React.Component {
  static propTypes = {
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onShowActions: PropTypes.func,
    quote: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      active_quote: null,
      modal: null,
      action: null,
      active_sort: 'premium',
      active_sort_direction: 'desc',
      quick_pdf: false,
      selected_quotes: [],
      show_share_modal: false,
      hover_id: null,
      firelight_eticket: null,
      firelight_eapp: null
    };
  }

  _handleHover = hover_id => {
    this.setState({ hover_id });
  };

  _handleToggleAll = e => {
    const quotes = this.context.quotes.map(q => q.id);
    const saved_quotes = this.context.saved_quotes.map(q => q.id);

    this.setState({
      selected_quotes: e.target.checked ? [...quotes, ...saved_quotes] : []
    });
  };

  _handleToggleQuote = id => {
    const should_add = !this.state.selected_quotes.includes(id);
    const selected_quotes = this.state.selected_quotes.filter(q_id => q_id !== id);

    if (should_add) {
      selected_quotes.push(id);
    }

    this.setState({ selected_quotes });
  };

  _handleSortClick = active_sort => {
    const new_state = { active_sort, active_sort_direction: 'desc' };

    if (this.state.active_sort === active_sort) {
      new_state.active_sort_direction = this.state.active_sort_direction === 'desc' ? 'asc' : 'desc';
    }

    this.setState(new_state);
  };

  _handleSaveRemoveClick = (id, save) => {
    this._handleHover(null);
    if (save) {
      this.props.onSaveClick(id);
    } else {
      this.props.onDeleteClick(id);
    }
  };

  _toggleQuoteModal = (quote, quick_pdf) => {
    this.setState({
      active_quote: quote ? Object.assign({}, quote) : null,
      quick_pdf
    });
  };

  _toggleShareModal = show_share_modal => {
    this.setState({ show_share_modal });
  };
  _setFirelightEapp = firelight_eapp => {
    this.setState({ firelight_eapp });
  };

  _toggleModal = modal => {
    this.setState({ modal });
  };
  _handleQuoteAction = action => {
    const actions = {
      firelight: 'firelight'
    };

    this.setState({
      action,
      modal: actions[action.action_type]
    });
  };

  _sort = quotes => {
    const { active_sort, active_sort_direction } = this.state;

    return [...quotes].sort((a, b) => {
      const sort_a = a[active_sort] || 0;
      const sort_b = b[active_sort] || 0;

      if (active_sort_direction === 'desc') {
        return sort_b - sort_a;
      }

      return sort_a - sort_b;
    });
  };

  render() {
    const styles = this.styles();
    const { knocked_out_quotes, layout, saved_quotes, quotes, criteria } = this.context;
    const { active_quote, active_sort, active_sort_direction, hover_id, quick_pdf, selected_quotes, show_share_modal, modal, action, firelight_eapp } = this.state;
    const sorted_quotes = this._sort(quotes);
    const sorted_saved_quotes = this._sort(saved_quotes);
    const sorted_knocked_out_quotes = this._sort(knocked_out_quotes);

    return (
      <React.Fragment>
        {active_quote ? <QuoteActionModal onClose={this._toggleQuoteModal.bind(null, null, false)} quickPdf={quick_pdf} quote={active_quote} /> : null}

        {show_share_modal ? <ShareModal onClose={this._toggleShareModal.bind(null, false)} quotes={selected_quotes} /> : null}

        {modal === 'quote_actions' ? <QuoteActions onAction={this._handleQuoteAction} onClose={this._toggleModal.bind(null, null)} quote={criteria} /> : null}

        {modal === 'firelight' && action.action_data.activity === 'e_app' ? (
          <FirelightEappModal action={action} eapp={firelight_eapp} onClose={this._toggleModal.bind(null, null)} onSave={this._setFirelightEapp} quote={sorted_quotes} />
        ) : null}

        <table style={styles.table}>
          <thead style={styles.table_head}>
            <tr>
              {[
                {
                  name: 'product_details',
                  label: (
                    <React.Fragment>
                      {layout !== 'consumer' && sorted_quotes.length + sorted_saved_quotes.length > 0 ? (
                        <input checked={selected_quotes.length === sorted_quotes.length + sorted_saved_quotes.length} onChange={this._handleToggleAll} style={{ marginRight: 20 }} type='checkbox' />
                      ) : null}
                      Product
                    </React.Fragment>
                  ),
                  style: styles.first_column
                },
                { name: 'premium', label: 'Premium', sortable: true },
                {
                  name: 'specified_amount',
                  label: 'Specified Amount',
                  sortable: true,
                  tooltip:
                    'This amount is used to determine LTC benefits and the death benefits as well as Policy Charges and Deductions from the Accumulated Value. The Specified Amount is not the same as the Net Death Benefit.'
                },
                { name: 'total_benefit', label: 'Day 1 Total Benefit', sortable: true, tooltip: 'This is the total amount of benefits available to the individual over the life of the policy.' },
                {
                  name: 'total_benefit_age_80',
                  label: 'Age 80 Total Benefit',
                  sortable: true,
                  tooltip: 'This is the total amount of benefits available to the individual over the life of the policy.'
                },
                { name: 'max_monthly_benefit', label: 'Day 1 Max Monthly Benefit', sortable: true, tooltip: 'This is the maximum amount of benefits available per month.' },
                { name: 'max_monthly_benefit_age_80', label: 'Age 80 Max Monthly Benefit', sortable: true, tooltip: 'This is the maximum amount of benefits available per month.' },
                { name: 'lump_sum_premium', label: 'Lump Sum Premium', sortable: true, tooltip: 'An initial amount paid in addition to the planned premium.' },
                { name: 'exchange_amount', label: '1035 Exchange Amount', sortable: true, tooltip: 'This is the amount of funds being applied to the policy via a 1035 exchange.' },
                {
                  name: 'guaranteed_min_death_benefit',
                  label: 'Guar. Min Death Benefit',
                  sortable: true,
                  tooltip: 'Guaranteed death benefit payout, even if the death benefit amount quoted is exhausted by the payment of LTC benefits.'
                },
                { name: 'net_death_benefit', label: 'Net Death Benefit', sortable: true },
                { name: 'guaranteed_interest_rate', label: 'Guar. Interest Rate', sortable: true }
              ].map(h => {
                const sorting = active_sort === h.name;
                const sort_icon = sorting && active_sort_direction === 'asc' ? 'mdi-sort-reverse-variant' : 'mdi-sort-variant';
                const sort_styles = sorting ? styles.sort_icon_active : styles.sort_icon;

                return (
                  <th key={`h-${h.name}`} style={Object.assign({}, styles.table_header, h.style)}>
                    <div style={styles.flex}>
                      {h.sortable ? <i className={`mdi ${sort_icon}`} onClick={this._handleSortClick.bind(null, h.name)} style={sort_styles} /> : null}
                      <div onClick={h.sortable ? this._handleSortClick.bind(null, h.name) : null} style={h.sortable ? { cursor: 'pointer' } : null}>
                        {h.label}
                      </div>
                      {h.tooltip ? <Tooltip position='left-top'>{h.tooltip}</Tooltip> : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody style={styles.saved_quotes}>
            {sorted_saved_quotes.map(q => (
              <tr
                key={q.id}
                onMouseEnter={this._handleHover.bind(null, q.id)}
                onMouseLeave={this._handleHover.bind(null, null)}
                style={Object.assign({}, styles.row, { background: `${this.context.color}05` })}
              >
                <td style={styles.first_column}>
                  <div style={styles.flex}>
                    <div style={styles.product_summary}>
                      {layout !== 'consumer' ? (
                        <input checked={selected_quotes.includes(q.id)} onChange={this._handleToggleQuote.bind(null, q.id)} style={{ marginRight: 20 }} type='checkbox' />
                      ) : null}
                      <div>
                        <div style={styles.product_name}>{q.product.name}</div>
                        <div style={styles.carrier_name}>{q.carrier.name}</div>
                        {hover_id === q.id ? (
                          <div style={{ display: 'flex', marginTop: 10 }}>
                            <Button onClick={this._toggleQuoteModal.bind(null, q, false)} small={true}>
                              More Detail
                            </Button>
                            {layout !== 'consumer' ? (
                              <React.Fragment>
                                <Button icon='pin-off' onClick={this._handleSaveRemoveClick.bind(null, q.id, false)} small={true} style={{ marginLeft: 4 }} />
                                <Button icon='file-pdf-box' onClick={this._toggleQuoteModal.bind(null, q, true)} small={true} style={{ marginLeft: 4 }} />
                              </React.Fragment>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div style={styles.product_options}>
                      <div style={{ lineHeight: '18px' }}>
                        {q.scheduled_premium} - {q.premium_mode}
                      </div>
                      <div style={{ lineHeight: '18px' }}>
                        {Numeral(q.benefit_duration).format('0')} Years <span style={styles.italic}>of Benefits</span>
                      </div>
                      <div style={{ lineHeight: '18px' }}>
                        {q.inflation_benefit || 'No'} <span style={styles.italic}>Inflation Protection</span>
                      </div>
                      <div style={{ lineHeight: '18px' }}>
                        {q.refund_of_premium} <span style={styles.italic}>Refund of Premium</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={styles.table_number}>{Numeral(q.premium).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.specified_amount).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.total_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>
                  {q.inflation_benefit === 'US Medical Care Inflation' ? <span style={{ fontSize: 13 }}>See Full Illustration</span> : Numeral(q.total_benefit_age_80).format('$0,00')}
                </td>
                <td style={styles.table_number}>{Numeral(q.max_monthly_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>
                  {q.inflation_benefit === 'US Medical Care Inflation' ? <span style={{ fontSize: 13 }}>See Full Illustration</span> : Numeral(q.max_monthly_benefit_age_80).format('$0,00')}
                </td>
                <td style={styles.table_number}>{Numeral(q.lump_sum_premium).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.exchange_amount).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.guaranteed_min_death_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.net_death_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.guaranteed_interest_rate / 100).format('0.00%')}</td>
              </tr>
            ))}
          </tbody>

          {layout !== 'consumer' && sorted_saved_quotes.length ? (
            <tr>
              <td colSpan='12' style={styles.divider}>
                <div style={styles.help_msg}>The quotes aboved are "pinned" quotes. You can pin quotes from different case design results to see them together.</div>
              </td>
            </tr>
          ) : null}

          <tbody>
            {sorted_quotes.map(q => (
              <tr key={q.id} onMouseEnter={this._handleHover.bind(null, q.id)} onMouseLeave={this._handleHover.bind(null, null)} style={styles.row}>
                <td style={styles.first_column}>
                  <div style={styles.flex}>
                    <div style={styles.product_summary}>
                      <input checked={selected_quotes.includes(q.id)} onChange={this._handleToggleQuote.bind(null, q.id)} style={{ marginRight: 20 }} type='checkbox' />
                      <div>
                        <div style={styles.product_name}>{q.product.name}</div>
                        {hover_id === q.id ? (
                          <Button onClick={this._toggleModal.bind(null, 'quote_actions')} small={true}>
                            Get Started
                          </Button>
                        ) : (
                          <div style={styles.carrier_name}>{q.carrier.name}</div>
                        )}
                        {hover_id === q.id ? (
                          <div style={{ display: 'flex', marginTop: 10 }}>
                            <Button onClick={this._toggleQuoteModal.bind(null, q, false)} small={true}>
                              More Detail
                            </Button>

                            <Button icon='pin' onClick={this._handleSaveRemoveClick.bind(null, q.id, true)} small={true} style={{ marginLeft: 4 }} />
                            <Button icon='file-pdf-box' onClick={this._toggleQuoteModal.bind(null, q, true)} small={true} style={{ marginLeft: 4 }} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div style={styles.product_options}>
                      <div style={{ lineHeight: '18px' }}>
                        {q.scheduled_premium} - {q.premium_mode}
                      </div>
                      <div style={{ lineHeight: '18px' }}>
                        {Numeral(q.benefit_duration).format('0')} Years <span style={styles.italic}>of Benefits</span>
                      </div>
                      <div style={{ lineHeight: '18px' }}>
                        {q.inflation_benefit || 'No'} <span style={styles.italic}>Inflation Protection</span>
                      </div>
                      <div style={{ lineHeight: '18px' }}>
                        {q.refund_of_premium} <span style={styles.italic}>Refund of Premium</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={styles.table_number}>{Numeral(q.premium).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.specified_amount).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.total_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>
                  {q.inflation_benefit === 'US Medical Care Inflation' ? <span style={{ fontSize: 13 }}>See Full Illustration</span> : Numeral(q.total_benefit_age_80).format('$0,00')}
                </td>
                <td style={styles.table_number}>{Numeral(q.max_monthly_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>
                  {q.inflation_benefit === 'US Medical Care Inflation' ? <span style={{ fontSize: 13 }}>See Full Illustration</span> : Numeral(q.max_monthly_benefit_age_80).format('$0,00')}
                </td>
                <td style={styles.table_number}>{Numeral(q.lump_sum_premium).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.exchange_amount).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.guaranteed_min_death_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.net_death_benefit).format('$0,00')}</td>
                <td style={styles.table_number}>{Numeral(q.guaranteed_interest_rate / 100).format('0.00%')}</td>
              </tr>
            ))}
          </tbody>

          {sorted_knocked_out_quotes.length ? (
            <tr>
              <td colSpan='12' style={styles.divider} />
            </tr>
          ) : null}

          <tbody>
            {sorted_knocked_out_quotes.map(q => (
              <tr key={q.id} style={Object.assign({}, styles.row, { background: '#f9f9f9' })}>
                <td style={Object.assign({}, styles.first_column, { background: '#f9f9f9' })}>
                  <div style={styles.flex}>
                    <div style={styles.product_summary}>
                      <div>
                        <div style={styles.product_name}>{q.product.name}</div>
                        <div style={styles.carrier_name}>{q.carrier.name}</div>
                      </div>
                    </div>
                    <div style={styles.product_options}>
                      <div>
                        {q.scheduled_premium} - {q.premium_mode}
                      </div>
                      <div>
                        {Numeral(q.benefit_duration).format('0')} Years <span style={styles.italic}>of Benefits</span>
                      </div>
                      <div>
                        {q.inflation_benefit || 'No'} <span style={styles.italic}>Inflation Protection</span>
                      </div>
                      <div>
                        {q.refund_of_premium} <span style={styles.italic}>Refund of Premium</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td colSpan={11} style={styles.provider_msg}>
                  {q.provider_message}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {layout !== 'consumer' ? (
          <div style={styles.buttons}>
            {selected_quotes.length ? (
              <Button onClick={this._toggleShareModal.bind(null, true)}>Create Shareable Link</Button>
            ) : (
              <Button disabled={true}>Select Quotes Above To See Their Actions</Button>
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  styles = () => {
    return {
      flex: {
        display: 'flex',
        alignItems: 'center'
      },
      italic: {
        fontStyle: 'italic',
        fontWeight: FontWeights.REGULAR,
        color: '#666'
      },

      results: {
        display: 'flex',
        padding: 40,
        justifyContent: 'space-between'
      },
      table: {
        width: '100%',
        overflowX: 'auto',
        display: 'block'
      },
      table_head: {
        borderBottom: '4px double #e5e5e5'
      },
      table_header: {
        padding: '15px 20px',
        whiteSpace: 'nowrap',
        fontSize: FontSizes.REGULAR,
        fontWeight: FontWeights.BOLD,
        textTransform: 'none',
        textAlign: 'left'
      },
      sort_icon: {
        fontSize: 18,
        cursor: 'pointer',
        marginRight: 6,
        transform: 'scaleX(-1)',
        color: '#ccc'
      },
      sort_icon_active: {
        fontSize: 18,
        cursor: 'pointer',
        marginRight: 6,
        transform: 'scaleX(-1)',
        color: this.context.color
      },
      row: {
        borderBottom: '1px solid #e5e5e5',
        background: '#fcfcfc'
      },

      first_column: {
        position: 'sticky',
        left: 0,
        background: '#fff',
        boxShadow: '#e5e5e5 -1px 0px 0px 0px inset',
        zIndex: 2,
        padding: '10px 10px 10px 20px',
        border: 'none'
      },
      product_summary: {
        display: 'flex',
        alignItems: 'center',
        width: 210
      },
      product_options: {
        width: 230,
        lineHeight: '1.7em',
        fontSize: FontSizes.REGULAR,
        fontWeight: FontWeights.BOLD
      },
      product_name: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.BOLD,
        marginBottom: 2,
        width: 170
      },
      carrier_name: {
        fontSize: FontSizes.MEDIUM
      },
      table_number: {
        padding: 20,
        verticalAlign: 'middle',
        textAlign: 'center',
        fontSize: FontSizes.LARGE,
        fontWeight: FontWeights.BOLD,
        border: 'none'
      },

      divider: {
        padding: 0,
        borderLeft: 'none',
        borderRight: 'none',
        position: 'relative',
        zIndex: 2,
        background: '#f9f9f9'
      },
      help_msg: {
        fontStyle: 'italic',
        color: '#999',
        padding: 15,
        position: 'sticky',
        left: 0,
        width: 800,
        zIndex: 2,
        fontSize: 14
      },

      saved_quotes: {
        backgroundColor: '#0075ff0d'
      },

      provider_msg: {
        verticalAlign: 'middle',
        fontSize: FontSizes.MEDIUM,
        fontStyle: 'italic',
        color: '#999',
        paddingLeft: 30
      },

      buttons: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
        padding: 20,
        borderTop: '1px solid #e5e5e5',
        position: 'sticky',
        bottom: 0,
        zIndex: 2,
        backgroundColor: '#fff',
        borderRadius: '0px 0px 5px 5px'
      }
    };
  };
}

Quotes.contextType = LtcContext;

export default Quotes;
